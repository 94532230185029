
var options = exports.options = require('./options');

exports.parser = require('./parsers/parser');
exports.refiner = require('./refiners/refiner');

exports.Parser = exports.parser.Parser;
exports.Refiner = exports.refiner.Refiner;
exports.Filter = exports.refiner.Filter;

exports.ParsedResult = require('./result').ParsedResult;
exports.ParsedComponents = require('./result').ParsedComponents;

var Chrono = function(option) {

    option = option || exports.options.casualOption();
    this.parsers = new Object(option.parsers);
    this.refiners = new Object(option.refiners);
};


Chrono.prototype.parse = function(text, refDate, opt) {

    refDate = refDate || new Date();
    opt = opt || {};
    opt.forwardDate = opt.forwardDate || opt.forwardDate;
    
    var allResults = [];

    this.parsers.forEach(function (parser) {
        var results = parser.execute(text, refDate, opt);
        allResults = allResults.concat(results);
    });

    allResults.sort(function(a, b) {
        return a.index - b.index;
    });

    this.refiners.forEach(function (refiner) {
        allResults = refiner.refine(text, allResults, opt);
    });
    
    return allResults;
};


Chrono.prototype.parseDate = function(text, refDate, opt) {
    var results = this.parse(text, refDate, opt);
    if (results.length > 0) {
        return results[0].start.date();
    }
    return null;
};

exports.Chrono = Chrono;
exports.strict = new Chrono( options.strictOption() );
exports.casual = new Chrono( options.casualOption() );

exports.en = new Chrono( options.mergeOptions([
    options.en.casual, options.commonPostProcessing]));

exports.en_GB = new Chrono( options.mergeOptions([
    options.en_GB.casual, options.commonPostProcessing]));

exports.de = new Chrono( options.mergeOptions([
    options.de.casual, options.en, options.commonPostProcessing]));

exports.pt = new Chrono( options.mergeOptions([
    options.pt.casual, options.en, options.commonPostProcessing]));

exports.es = new Chrono( options.mergeOptions([
    options.es.casual, options.en, options.commonPostProcessing]));

exports.fr = new Chrono( options.mergeOptions([
    options.fr.casual, options.en, options.commonPostProcessing]));

exports.ja = new Chrono( options.mergeOptions([ 
    options.ja.casual, options.en, options.commonPostProcessing]));


exports.parse = function () {
    return exports.casual.parse.apply(exports.casual, arguments);
};

exports.parseDate = function () {
    return exports.casual.parseDate.apply(exports.casual, arguments);
};




